const filterElements = (elements, filters, keepOptional = true) => {
  return elements.filter((element) => {
    if (!keepOptional && element.optional) {
      return false;
    }
    return filters.indexOf(element.unitId) >= 0;
  });
};

module.exports = filterElements;
