const extractExtraFieldFromElement = (element, key) => {
  if (typeof element.TSOrderline === 'undefined' || typeof element.TSOrderline.extraFieldValues === 'undefined') {
    return null;
  }

  const filtered = element.TSOrderline.extraFieldValues.filter((field) => field.name === key);

  return filtered.length ? filtered[0] : null;
};

module.exports = extractExtraFieldFromElement;
