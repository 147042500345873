const parseDate = require('helpers/parseDate');

const elementsForDate = (allElements, date) => {
  return allElements.filter((element) => {
    const startDateTime = parseDate(element.date).getTime();
    const endDateTime = parseDate(element.endDate).getTime();
    const currentDateTime = date.getTime();

    return (currentDateTime >= startDateTime && currentDateTime <= endDateTime);
  });
};

module.exports = elementsForDate;
