const formatVtbHtml = (html) => {
  if (!html) {
    return '';
  }

  if (html.indexOf('<body') >= 0) {
    const regex = /<body[^>]*>((.|[\n\r])*)<\/body>/im;

    const parsedHtml = regex.exec(html);

    return typeof parsedHtml[1] !== 'undefined' ? parsedHtml[1] : html;
  }

  // Remove all div's and replace with br at end of lines
  html = html.replace(/<\/div><div>/g, '<br>');
  html = html.replace(/<\/?div>/g, '');

  // Add <ul>
  html = html.replace(/^-/, '<ul>-');
  html += '</li></ul>';

  // Replace all br's (end of lines) with closing list items
  html = html.replace(/<br>-\s/g, '</li><br>- ');

  // The first ul and dash should be replaced with a starting li
  html = html.replace(/<ul>-\s/g, '<ul><li>');

  // Everywhere where it starts with a br and a dash it is the start of a list item
  html = html.replace(/<br>-\s/g, '<li>');

  return html;
};

module.exports = formatVtbHtml;
