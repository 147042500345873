const parseFaq = (faq) => {
  if (!faq || typeof faq !== 'string') {
    return [];
  }

  // Strip HTML
  const faqStripped = faq.replace(/(<([^>]+)>)/gi, '');

  // Trim whitespace
  const faqTrimmed = faqStripped.trim();

  const questions = faqTrimmed.split('#');

  return questions
    .map(question => question.trim())
    .filter(question => question)
    .map((section) => {
      const questionMatch = /(.+)\n/.exec(section);

      const question = questionMatch && typeof questionMatch[1] !== 'undefined'
        ? questionMatch[1]
        : '';

      const answer = section
        .replace(question, '')
        .trim()
        .replace(/\n/g, '<br>');

      return {
        question,
        answer,
      };
    });
};

module.exports = parseFaq;
