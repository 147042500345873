const getActiveRoom = require('helpers/getActiveRoom');
const flatDeep = require('helpers/flatDeep');
const filterElements = require('helpers/filterElements');
const extractExtraFieldFromElement = require('helpers/extractExtraFieldFromElement');
const elementsForDate = require('helpers/elementsForDate');
const parseDate = require('helpers/parseDate');
const formatPrice = require('helpers/formatPrice');
const formatVtbHtml = require('helpers/formatVtbHtml');
const parseFaq = require('helpers/parseFaq');
const formatCoords = require('helpers/formatCoords');
const googleMapsLink = require('helpers/googleMapsLink');

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const TYPE_IDS = {
  PROGRAM: 1,
  PRICE: 2,
  FLIGHT: 4,
  FLIGHT_CLIENT: 6,
  INSURANCE: 7,
  HIDE: 8,
  ADDITIONS: 9,
  SURCHARGE: 10,
  FLIGHT_ADDITIONS: 11,
  LIJMSTEEN: 12,
};

const UNIT_IDS = {
  DAYS: 1,
  NIGHTS: 2,
  X: 3,
  EXCURSION: 4,
  EXCURSION_DAYS: 5,
  FLEX_DAYS: 6,
  FLEX_NIGHTS: 7,
  FLIGHT: 8,
  FLIGHT_NIGHT: 9,
  TRANSFER: 10,
  DOMFLIGHT: 11,
  TEXT: 12,
  FERRY: 13,
  WEEKS: 14,
  TEXTPERDAG: 16,
  INTERNAL: 17,
  TEXT_NIGHT: 21,
};

const FLIGHT_UNIT_IDS = [
  UNIT_IDS.FLIGHT,
  UNIT_IDS.FLIGHT_NIGHT,
  UNIT_IDS.DOMFLIGHT,
];

const customTransforms = {
  'extraFieldsTransformer': (vtbObj, params) => {
    console.log(vtbObj);

    const groupFields = vtbObj.dst.extraFieldValues.map(group => group.fields);
    const fields = [].concat(...groupFields);

    const extractTravelInformationField = (key) => {
      const filtered = fields.filter((field) => field.name === key);

      return filtered.length ? filtered[0] : null;
    };

    vtbObj.dst.extraFields = {
      practicalInformation: extractTravelInformationField('praktische_informatie'),
      service: extractTravelInformationField('product_service'),
      faq: extractTravelInformationField('faq'),
      faqConfirmation: extractTravelInformationField('FAQ_conf'),
      practicalInformationConfirmation: extractTravelInformationField('praktische_informatie_conf'),
      including: extractTravelInformationField('inclusief_tekst'),
      excluding: extractTravelInformationField('exclusief_tekst'),
      notes: extractTravelInformationField('Notities'),
      travelTimes: extractTravelInformationField('Reistijden'),
      travelTitle: extractTravelInformationField('naam_reis'),
      travelNames: extractTravelInformationField('naam_header'),
      travelSpecialist: extractTravelInformationField('travelspecialist'),
      bestemming1: extractTravelInformationField('bestemming1'),
      status: extractTravelInformationField('Status'),
    };

    if (vtbObj.dst.extraFields.status) {
      vtbObj.dst.extraFields.status = vtbObj.dst.extraFields.status.value;
    } else {
      vtbObj.dst.extraFields.status = 'Offerte';
    }

    vtbObj.dst.extraFields.includingFormatted = formatVtbHtml(vtbObj.dst.extraFields.including.value);
    vtbObj.dst.extraFields.excludingFormatted = formatVtbHtml(vtbObj.dst.extraFields.excluding.value);

    return vtbObj;
  },

  'sliderTransformer': (vtbObj, params) => {
    if (typeof vtbObj.dst.cover === 'undefined' || !Array.isArray(vtbObj.dst.cover)) {
      vtbObj.dst.slides = [];
    } else {
      vtbObj.dst.slides = vtbObj.dst.cover.map((coverSlide) => {
        coverSlide.url = coverSlide.url
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')
          .replace('/wordpress/medium/', '/wordpress/full/')
          .replace('/original/lg/', '/wordpress/full/')
          .replace('/landscape/st/', '/wordpress/full/');

        coverSlide.title = vtbObj.dst.title;
        coverSlide.subTitle = `${vtbObj.dst.extraFields.travelTitle.value}<br>${vtbObj.dst.extraFields.travelNames.value}`;

        return coverSlide;
      });
    }

    const elements = [].concat(...vtbObj.dst.segments.map(segment => segment.elements));

    elements.forEach((element) => {
      const extraFieldsForElement = typeof element.TSOrderline !== 'undefined'
        ? element.TSOrderline.extraFieldValues
        : null;

      if (!extraFieldsForElement) {
        return;
      }

      const extraFieldForIdentifier = (key) => {
        const fields = extraFieldsForElement.filter((field) => field.name === key);
        return fields.length && typeof fields[0].value !== 'undefined' && fields[0].value && typeof fields[0].value === 'string'
          ? fields[0].value.trim()
          : null;
      };

      let showInIntro = parseInt(extraFieldForIdentifier('hide_intro'), 10) !== 1;
      const introTitle = extraFieldForIdentifier('intro_titel');
      const introSubTitle = extraFieldForIdentifier('intro_text');

      if (!introTitle) {
        showInIntro = false;
      }

      if (showInIntro) {
        const media = element.media;

        let url = media.length ? media[0].url : 'https://placehold.it/800x500';

        url = url.replace('/original/lg/', '/wordpress/full/');
        url = url.replace('/wordpress/medium/', '/wordpress/full/');
        url = url.replace('/landscape/st/', '/wordpress/full/');

        vtbObj.dst.slides.push({
          url,
          title: introTitle,
          subTitle: introSubTitle,
        });
      }
    });

    return vtbObj;
  },

  'backgroundTransformer': (vtbObj, params) => {
    let destination = vtbObj.dst.extraFields.bestemming1.value;

    if (destination) {
      destination = destination.replace('Madagaskar', 'Madagascar');
      destination = destination.toLowerCase();
    }

    if (!destination) {
      destination = 'filipijnen';
    }

    vtbObj.dst.destinationName = destination;

    return vtbObj;
  },

  'overviewElementsTransformer': (vtbObj, params) => {
    const days = [];

    const allSegments = vtbObj.dst.segments;

    const allElements = flatDeep(allSegments.map((segment) => {
      const elements = segment.elements;

      return elements.map((element) => {
        element.segmentTypeId = segment.typeId;
        return element;
      });
    }));

    const segmentForDate = (date) => {
      const segments = allSegments.filter((segment) => {
        const startDateTime = parseDate(segment.date).getTime();
        const endDateTime = parseDate(segment.endDate).getTime();
        const currentDateTime = parseDate(date).getTime();

        return (currentDateTime >= startDateTime && currentDateTime <= endDateTime);
      });

      if (segments.length > 1) {
        return segments[1];
      }

      return segments.length ? segments[0] : null;
    };

    const startDate = new Date(vtbObj.dst.startDate);

    for (let i = 0; i < vtbObj.dst.totalDaysAllocated; i++) {
      const date = startDate.addDays(i);

      const elements = elementsForDate(allElements, date);

      const accommodationElements = filterElements(elementsForDate(allElements, date), [
        UNIT_IDS.DAYS,
        UNIT_IDS.NIGHTS,
        UNIT_IDS.FLEX_DAYS,
        UNIT_IDS.FLEX_NIGHTS,
      ])
        .filter((element) => {
          const { endDate } = element;

          return parseDate(endDate).getTime() !== date.getTime();
        });

      const arrangementElements = filterElements(elementsForDate(allElements, date), [
        // UNIT_IDS.X,
        UNIT_IDS.EXCURSION,
        UNIT_IDS.EXCURSION_DAYS,
        UNIT_IDS.FLIGHT,
        UNIT_IDS.FLIGHT_NIGHT,
        UNIT_IDS.TEXT,
        UNIT_IDS.TEXT_NIGHT,
        UNIT_IDS.WEEKS,
      ]).filter((element) => {
        return element.segmentTypeId !== 6;
      });

      const segment = segmentForDate(date);

      days.push({
        day: i + 1,
        date,
        segment,
        arrangementElements,
        accommodationElements,
      });
    }

    vtbObj.dst.overviewElements = days;

    return vtbObj;
  },

  'travelTransformer': (vtbObj, params) => {
    vtbObj.dst.flightInfo = [];
    vtbObj.dst.ferryInfo = [];

    vtbObj.dst.segments.forEach(((segment) => {
      const transportElements = filterElements(segment.elements, [
        UNIT_IDS.FERRY,
      ]);

      segment.flightInfo.forEach((flightInfo) => {
        vtbObj.dst.flightInfo.push(flightInfo);
      });

      transportElements.forEach((element) => {
        element.extraFields = {};

        if (typeof element.TSOrderline === 'undefined') {
          return;
        }

        element.TSOrderline.extraFieldValues.forEach((field) => {
          element.extraFields[field.name] = field.value;
        });

        if (element.unitId === UNIT_IDS.FERRY) {
          vtbObj.dst.ferryInfo.push(element);
        } else if (element.unitId === UNIT_IDS.FLIGHT || element.unitId === UNIT_IDS.FLIGHT_NIGHT) {
          vtbObj.dst.flightInfo.push(element);
        }
      });
    }));

    vtbObj.dst.flights = vtbObj.dst.segments.map((segment) => {
      const flightElements = filterElements(segment.elements, FLIGHT_UNIT_IDS, false);

      /** @var array flightInfo */
      const flightInfo = segment.flightInfo;

      flightElements.forEach((flightElement) => {
        const airlineName = extractExtraFieldFromElement(flightElement, 'airline').value;
        const arrivalAirport = extractExtraFieldFromElement(flightElement, 'to_place').value;
        const departureAirport = extractExtraFieldFromElement(flightElement, 'from_place').value;

        if (!airlineName || !arrivalAirport || !departureAirport) {
          return;
        }

        flightInfo.push({
          airlineObject: {
            name: airlineName,
          },
          arrivalAirport: arrivalAirport,
          arrivalDate: flightElement.endDate,
          arrivalTime: extractExtraFieldFromElement(flightElement, 'to_time').value,
          departureAirport: departureAirport,
          departureDate: flightElement.date,
          departureTime: extractExtraFieldFromElement(flightElement, 'from_time').value,
          flightNumber: extractExtraFieldFromElement(flightElement, 'flight_number').value,
        });
      });

      return flightInfo;
    }).filter((segment) => segment.length);

    vtbObj.dst.flights = flatDeep(vtbObj.dst.flights, 1);

    vtbObj.dst.hasFlights = vtbObj.dst.flights.length > 0;
    vtbObj.dst.hasFerries = vtbObj.dst.ferryInfo.length > 0;

    return vtbObj;
  },

  'programTransformer': (vtbObj, params) => {
    vtbObj.dst.programSegments = vtbObj.dst.segments.filter((segment) => {
      return (
        segment.typeId === TYPE_IDS.PROGRAM
        || segment.typeId === TYPE_IDS.FLIGHT
        || segment.typeId === TYPE_IDS.FLIGHT_NIGHT
        || segment.typeId === TYPE_IDS.FLIGHT_CLIENT
      );
    }).map((segment, index) => {
      segment.index = index;

      segment.showHeading = index === 0;

      return segment;
    });

    return vtbObj;
  },

  'personsTransformer': (vtbObj) => {
    vtbObj.dst.persons = [];

    Object.keys(vtbObj.dst.participants).forEach((partyKey) => {
      const party = vtbObj.dst.participants[partyKey];

      party.forEach((person) => {
        vtbObj.dst.persons.push(person);
      });
    });

    return vtbObj;
  },

  'pricesTransformer': (vtbObj, params) => {
    vtbObj.dst.segments = vtbObj.dst.segments.map((segment) => {
      const numberOfPersons = vtbObj.dst.persons.length;

      segment.elements = segment.elements.map((element, elementIndex) => {
        const salesTotal = element.olPrices.salesTotal;

        if (typeof element.TSOrderline === 'undefined') {
          element.TSOrderline = {};
        }

        if (typeof element.TSOrderline.id === 'undefined') {
          element.TSOrderline.id = 'random-' + Math.round((Math.random() * 9999999) + 1111111);
        }

        element.sellingAmountFormatted = formatPrice(salesTotal).replace(',00', ',-');
        element.sellingAmountPerson = element.olPrices.salesTotal / numberOfPersons;
        element.sellingAmountPersonFormatted = formatPrice(element.sellingAmountPerson).replace(',00', ',-');

        element.additionalCosts = 0;
        element.additionalCostsPerson = 0;
        element.additionalCostsFormatted = '';
        element.additionalCostsPersonFormatted = '';

        let previousElement = typeof segment.elements[elementIndex - 1] !== 'undefined'
          ? segment.elements[elementIndex - 1]
          : null;

        if (
          element.optional
          && previousElement
          && previousElement.olPrices
          && !previousElement.optional
          && element.unitId === previousElement.unitId
        ) {
          const previousElementSalesTotal = previousElement.olPrices.salesTotal;

          element.additionalCosts = element.olPrices.salesTotal - previousElementSalesTotal;
          element.additionalCostsPerson = element.additionalCosts / numberOfPersons;

          element.additionalCostsFormatted = formatPrice(element.additionalCosts).replace(',00', ',-');
          element.additionalCostsPersonFormatted = formatPrice(element.additionalCostsPerson).replace(',00', ',-');
        } else if (element.optional) {
          element.additionalCosts = element.olPrices.salesTotal;
          element.additionalCostsPerson = element.additionalCosts / numberOfPersons;

          element.additionalCostsFormatted = formatPrice(element.additionalCosts).replace(',00', ',-');
          element.additionalCostsPersonFormatted = formatPrice(element.additionalCostsPerson).replace(',00', ',-');
        }

        return element;
      });

      return segment;
    });

    return vtbObj;
  },

  'segmentTransformer': (vtbObj) => {
    let excursionIndex = 0;
    let accommodationIndex = 0;

    vtbObj.dst.segments = vtbObj.dst.segments.map((segment) => {
      segment.elements = segment.elements.map((element) => {
        element.activeRoom = getActiveRoom(element);

        element.note = extractExtraFieldFromElement(element, 'product_note').value;

        element.media = Array.isArray(element.media)
          ? element.media.map((mediaItem) => {
            mediaItem.url = mediaItem.url.replace('/wordpress/medium/', '/landscape/st/');
            return mediaItem;
          })
          : [];

        return element;
      });

      segment.programElements = filterElements(segment.elements, [
        UNIT_IDS.DAYS,
        UNIT_IDS.EXCURSION,
        UNIT_IDS.EXCURSION_DAYS,
        UNIT_IDS.TEXTPERDAG,
      ]);

      const programElementIndexesToRemove = [];

      segment.programElements.map((programElement, index) => {
        programElement.optionalExcursions = [];

        if (programElement.unitId !== UNIT_IDS.EXCURSION) {
          return programElement;
        }

        const nextProgramElement = typeof segment.programElements[index + 1] !== 'undefined'
          ? segment.programElements[index + 1]
          : null;

        if (
          !programElement.optional
          && nextProgramElement
          && nextProgramElement.unitId === UNIT_IDS.EXCURSION
          && nextProgramElement.optional
        ) {
          programElement.optionalExcursions.push(nextProgramElement);
          programElementIndexesToRemove.push(index + 1);
        }

        return programElement;
      });

      programElementIndexesToRemove.reverse().forEach((programElementIndex) => {
        segment.programElements.splice(programElementIndex, 1);
      });

      segment.textElements = filterElements(segment.elements, [
        UNIT_IDS.TEXT,
      ]);

      segment.primaryElement = segment.textElements.length ? segment.textElements[0] : null;

      segment.overviewElements = filterElements(segment.elements, [
        UNIT_IDS.EXCURSION,
        UNIT_IDS.EXCURSION_DAYS,
        UNIT_IDS.FLIGHT,
        UNIT_IDS.FLIGHT_NIGHT,
        UNIT_IDS.DOMFLIGHT,
        UNIT_IDS.FERRY,
      ], false);

      segment.excursionElements = filterElements(segment.elements, [
        UNIT_IDS.EXCURSION,
        UNIT_IDS.EXCURSION_DAYS,
      ]).map((excursionElement) => {
        excursionElement.excursionIndex = excursionIndex;

        excursionIndex += 1;

        return excursionElement;
      });

      segment.programArrangementElements = segment.elements.filter((element) => {
        return (element.unitId !== UNIT_IDS.TEXT && element.unitId !== UNIT_IDS.TEXTPERDAG && !element.optional);
      });

      segment.optionalArrangementElements = segment.excursionElements.filter((element) => {
        return element.optional;
      });

      segment.accommodationElements = filterElements(segment.elements, [
        UNIT_IDS.DAYS,
        UNIT_IDS.NIGHTS,
      ], true).map((accommodationElement) => {
        accommodationElement.accommodationIndex = accommodationIndex;

        accommodationIndex += 1;

        return accommodationElement;
      });

      return segment;
    });

    return vtbObj;
  },

  'priceStatisticTransformer': (vtbObj, params) => {
    vtbObj.dst.priceStatistic = {
      flights: 0,
      arrangements: 0,
      total: 0,
    };

    vtbObj.dst.priceStatisticPerPerson = {
    };

    vtbObj.dst.numberOfPersons = 0;

    const includedGroups = {
      flights: [],
      domflights: [],
      ferries: [],
      flexdays: [],
      excursions: [],
      other: [],
    };

    const addPrice = (type, olPrices) => {
      if (!olPrices || !olPrices.salesTotal) {
        return;
      }

      const price = parseFloat(olPrices.salesTotal);

      vtbObj.dst.priceStatistic[type] += price;

      vtbObj.dst.priceStatistic.total += price;

      // Check if we've prices per person for this element
      if (typeof olPrices.participants === 'undefined') {
        return;
      }

      // Loop through every person in this itinerary
      vtbObj.dst.persons.forEach((person) => {
        if (typeof olPrices.participants[person.id] === 'undefined') {
          return;
        }

        const priceForPerson = parseFloat(olPrices.participants[person.id].salesPrice);

        if (!isNaN(priceForPerson)) {
          vtbObj.dst.priceStatisticPerPerson[person.id][type] += priceForPerson;

          vtbObj.dst.priceStatisticPerPerson[person.id].total += priceForPerson;
        }
      });
    };

    // Initialize prices object for every person in the itinerary
    vtbObj.dst.persons.forEach((person) => {
      vtbObj.dst.priceStatisticPerPerson[person.id] = {};
      vtbObj.dst.priceStatisticPerPerson[person.id].flights = 0;
      vtbObj.dst.priceStatisticPerPerson[person.id].arrangements = 0;
      vtbObj.dst.priceStatisticPerPerson[person.id].total = 0;
      vtbObj.dst.priceStatisticPerPerson[person.id].name = person.name;

      vtbObj.dst.numberOfPersons += 1;
    });

    vtbObj.dst.segments.forEach((segment) => {
      // Remove flight_client type from included prices list.
      if (segment.typeId === 6) {
        return;
      }

      segment.elements.forEach((element) => {
        if (element.optional) {
          return;
        }

        if (element.unitId === UNIT_IDS.INTERNAL) {
          return;
        }

        if (element.unitId === UNIT_IDS.FERRY) {
          addPrice('arrangements', element.olPrices);

          const { from_time: fromTime, to_time: toTime } = element.extraFields;

          includedGroups.ferries.push(`${element.subTitle} ${fromTime} - ${toTime}`);
        } else if (element.unitId === UNIT_IDS.FLIGHT || element.unitId === UNIT_IDS.FLIGHT_NIGHT) {
          addPrice('flights', element.olPrices);
          includedGroups.flights.push(`${element.title} - ${element.subTitle} ${element.subAdditionalText ? element.subAdditionalText : ''}`);
        } else if (element.unitId === UNIT_IDS.DOMFLIGHT) {
          addPrice('arrangements', element.olPrices);
          includedGroups.domflights.push(`${element.subTitle} ${element.subAdditionalText ? element.subAdditionalText : ''}`);
        } else if (element.unitId === UNIT_IDS.FLEX_DAYS) {
          addPrice('arrangements', element.olPrices);
          includedGroups.flexdays.push(`${element.days} dagen ${element.title} ${element.subTitle}`);
        } else if (
          element.unitId === UNIT_IDS.EXCURSION ||
          element.unitId === UNIT_IDS.EXCURSION_DAYS
        ) {
          addPrice('arrangements', element.olPrices);
          includedGroups.excursions.push(element.title);
        } else if (
          element.unitId === UNIT_IDS.NIGHTS
          || element.unitId === UNIT_IDS.TRANSFER
          || element.unitId === UNIT_IDS.TEXT
          || element.unitId === UNIT_IDS.TEXTPERDAG
          || element.unitId === UNIT_IDS.X
        ) {
          addPrice('arrangements', element.olPrices);
        } else {
          addPrice('arrangements', element.olPrices);
          includedGroups.other.push(element.subTitle);
        }
      });
    });

    vtbObj.dst.includedList = [];

    Object.keys(includedGroups).forEach((key) => {
      const arr = includedGroups[key];

      arr.forEach((item) => {
        if (!item) {
          return;
        }

        // Remove note between {} from the text
        let itemText = item.replace(/{.+}/, '');
        itemText = itemText.replace(/\[(\w|\s|\d|\.|-|!|\?)+\]$/, '')

        vtbObj.dst.includedList.push(itemText);
      });
    });

    Object.keys(vtbObj.dst.priceStatistic).forEach((priceKey) => {
      const price = vtbObj.dst.priceStatistic[priceKey];

      vtbObj.dst.priceStatistic[priceKey] = formatPrice(price, 2, '€ ');
    });

    vtbObj.dst.persons.forEach((person) => {
      vtbObj.dst.priceStatisticPerPerson[person.id].flights = formatPrice(vtbObj.dst.priceStatisticPerPerson[person.id].flights, 2, '€ ');
      vtbObj.dst.priceStatisticPerPerson[person.id].arrangements = formatPrice(vtbObj.dst.priceStatisticPerPerson[person.id].arrangements, 2, '€ ');
      vtbObj.dst.priceStatisticPerPerson[person.id].total = formatPrice(vtbObj.dst.priceStatisticPerPerson[person.id].total, 2, '€ ');
    });

    return vtbObj;
  },

  'reservationUrlTransformer': (vtbObj) => {
    const baseUrl = 'https://www.undiscovered.nl/boekingsformulier';

    const participantParties = vtbObj.dst.participants;
    const participants = [].concat(...Object.values(participantParties));

    const paramsObject = {
      quotation_reference_number: vtbObj.dst.TSOrder.sourceDescription,
    };

    const firstParticipant = participants.length ? participants[0] : null;

    if (firstParticipant) {
      paramsObject.first_name = firstParticipant.name;
      paramsObject.insertion = firstParticipant.surname_prefix;
      paramsObject.last_name = firstParticipant.surname.replace(paramsObject.insertion, '').trim();
    }

    paramsObject.travellers_count = participants.length;

    paramsObject.employee = vtbObj.dst.travelSpecialist.name;

    const params = Object.keys(paramsObject).map((paramKey) => {
      return `${paramKey}=${encodeURIComponent(paramsObject[paramKey])}`;
    });

    vtbObj.dst.reservationUrl = `${baseUrl}?${params.join('&')}`;

    return vtbObj;
  },

  'travelSpecialistTransformer': (vtbObj) => {
    vtbObj.dst.travelSpecialist = {
      image: 'https://placehold.it/480x480/',
      name: vtbObj.dst.extraFields.travelSpecialist ? vtbObj.dst.extraFields.travelSpecialist.value : '',
    };

    const travelSpecialistImageMap = {
      'Malvina': 'https://www.undiscovered.nl/media/malvina1/offer_avatar/50x50/malvina1.jpg?x=50&y=50',
      'Sara': 'https://www.undiscovered.nl/media/sara-1/offer_avatar/50x50/sara-1.jpg?x=50&y=50',
      'Tom': 'https://www.undiscovered.nl/media/img-0593/offer_avatar/50x50/img-0593.jpg?x=90&y=50',
      'Eric': 'https://www.undiscovered.nl/media/img-7302/offer_avatar/50x50/img-7302.jpg?x=50&y=50',
      'Léonie': 'https://www.undiscovered.nl/media/leonie-1640x480/offer_avatar/50x50/leonie-1640x480.jpg?x=50&y=50',
      'Edwin': 'https://www.undiscovered.nl/media/edwin-vtb/offer_avatar/50x50/edwin-vtb.jpg',
    };

    if (typeof travelSpecialistImageMap[vtbObj.dst.travelSpecialist.name] !== 'undefined') {
      vtbObj.dst.travelSpecialist.image = travelSpecialistImageMap[vtbObj.dst.travelSpecialist.name];
    }

    return vtbObj;
  },

  'faqTransformer': (vtbObj) => {
    vtbObj.dst.extraFields.faq.parsed = parseFaq(vtbObj.dst.extraFields.faq.value);

    if (vtbObj.dst.extraFields.status === 'Boekingsbevestiging') {
      vtbObj.dst.extraFields.faq.parsed = parseFaq(vtbObj.dst.extraFields.faqConfirmation.value);
    }

    vtbObj.dst.extraFields.faq.chunks = [];

    if (Array.isArray(vtbObj.dst.extraFields.faq.parsed)) {
      vtbObj.dst.extraFields.faq.chunks = vtbObj.dst.extraFields.faq.parsed.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 5);

        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      console.log(vtbObj.dst.extraFields.faq.chunks);
    }

    return vtbObj;
  },

  'coordinatesTransformer': (vtbObj) => {
    vtbObj.dst.segments = vtbObj.dst.segments.map((segment) => {
      if (typeof segment.maps !== 'undefined') {
        let formattedCoordinates = '';
        let mapsLink = '';

        if (
          typeof segment.maps.latitude !== 'undefined'
          && typeof segment.maps.longitude !== 'undefined'
          && segment.maps.latitude
          && segment.maps.longitude
        ) {
          const coords = formatCoords(segment.maps.latitude, segment.maps.longitude);

          formattedCoordinates = coords.format('FFf');

          mapsLink = googleMapsLink(segment.maps.latitude, segment.maps.longitude);
        }

        segment.maps.formatted = formattedCoordinates;
        segment.maps.link = mapsLink;
      }

      segment.elements = segment.elements.map((element) => {
        if (typeof element.maps !== 'undefined') {
          let formattedCoordinates = '';
          let mapsLink = '';

          if (
            typeof element.maps.latitude !== 'undefined'
            && typeof element.maps.longitude !== 'undefined'
            && element.maps.latitude
            && element.maps.longitude
          ) {
            const coords = formatCoords(element.maps.latitude, element.maps.longitude);

            formattedCoordinates = coords.format('FFf');

            mapsLink = googleMapsLink(element.maps.latitude, element.maps.longitude);
          }

          element.maps.formatted = formattedCoordinates;
          element.maps.link = mapsLink;
        }

        return element;
      });

      return segment;
    });

    return vtbObj;
  },
};

module.exports = customTransforms;
