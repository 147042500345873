const getActiveRoom = (element) => {
  const subTitle = element.subTitle;

  let activeRoom = null;

  if (!Array.isArray(element.roomTypes) || !element.roomTypes.length) {
    return null;
  }

  const findActiveRoomTypes = element.roomTypes.filter((roomType) => {
    return roomType.description === subTitle;
  });

  if (typeof findActiveRoomTypes[0] === 'undefined' || !findActiveRoomTypes[0]) {
    return null;
  }

  activeRoom = findActiveRoomTypes[0];

  if (typeof activeRoom.note !== 'undefined') {
    // Get all text between {} (e.g. "In dit hotel is {ontbijt}")
    const note = /\{(.+)}/.exec(activeRoom.note);

    let noteExcludingCurlyBraces = activeRoom.note;

    if (note && typeof note[1] !== 'undefined') {
      activeRoom.note = note[1];
      noteExcludingCurlyBraces = noteExcludingCurlyBraces.replace(note[0], '');
    }

    activeRoom.noteExcludingCurlyBraces = noteExcludingCurlyBraces;
  }

  return activeRoom;
};

module.exports = getActiveRoom;
